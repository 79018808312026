export const personalityTypeList = [
  {
    label: '销售',
    value: 'SALE',
  },
  {
    label: '门店',
    value: 'STORE',
  },
];

export const personalityTypeObject = personalityTypeList.reduce((pre, cur) => ((pre[cur.value] = cur.label), pre), {});

export const runningStatusList = [
  {
    value: 'NORMAL',
    text: '运营中账号',
    number: 0,
  },
  {
    value: 'AUTHORIZED',
    text: '数据已授权',
    number: 0,
  },
  {
    value: 'EXPIRED',
    text: '授权已失效',
    number: 0,
  },
];

export const stopStatusList = [
  {
    value: 'STOP',
    text: '已停止运营',
    number: 0,
  },
];

export const columns = [
  {
    align: 'center',
    width: 230,
    title: '媒体号',
    scopedSlots: { customRender: 'mediaAccount' },
  },
  {
    align: 'center',
    title: '数据对齐',
    width: 120,
    scopedSlots: { customRender: 'p1OauthStatus' },
  },
  {
    slots: { title: 'operatorTitle' },
    align: 'center',
    dataIndex: 'masterAccountName',
    width: 150,
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '人设',
    width: 80,
    scopedSlots: { customRender: 'characters' },
  },
  {
    align: 'center',
    title: '所属成员',
    width: 200,
    scopedSlots: { customRender: 'belongMember' },
  },
  {
    align: 'center',
    title: '所属品牌',
    width: 150,
    dataIndex: 'principalName',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '操作人',
    width: 150,
    dataIndex: 'actor',
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '操作时间',
    dataIndex: 'atime',
    width: 150,
    customRender: (text) => {
      return text || '-';
    },
  },
  {
    align: 'center',
    title: '操作',
    fixed: 'right',
    width: 160,
    scopedSlots: { customRender: 'operation' },
  },
];
