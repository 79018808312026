<template>
  <div class="AddAccountModalComp">
    <a-modal
      width="600px"
      :zIndex="1001"
      title="添加小红书号"
      :maskClosable="false"
      :visible="showAddMedia"
      @close="handleMediaClose"
      @cancel="handleMediaClose"
    >
      <a-tabs :activeKey="addMediaParams.type" @change="handleTabChange">
        <a-tab-pane key="batch" tab="批量添加">
          <div>
            <div style="display: flex; justify-content: space-between">
              <span
                >小红书号 <span style="color: #f00; margin-right: 15px">*</span
                ><span>
                  <a-popover title="">
                    <template slot="content">
                      <img style="width: 300px" src="@/assets/images/xhs-guide.png" alt="引导图" />
                      <div style="font-weight: bold">打开小红书账号主页，点击“小红书号“</div>
                    </template>
                    <a-button icon="question-circle" type="link">如何获取小红书号</a-button>
                  </a-popover></span
                ></span
              >
              <span>已输入 {{ addCount }} 个</span>
            </div>
            <a-textarea
              v-model.trim="addMediaParams.account"
              placeholder="输入小红书号，每个小红书号一行"
              :auto-size="{ minRows: 15, maxRows: 15 }"
            ></a-textarea>
            <div>1、支持复制粘贴方式新增，每行一个小红书号</div>
            <div>2、每次最多 100 行</div>
            <div>3、已添加过的账号会自动忽略</div>
            <div>4、运营负责人根据账号所属成员的负责人自动设置</div>
          </div>
        </a-tab-pane>

        <a-tab-pane key="single" tab="单账号添加" force-render>
          <div style="padding-left: 16px">
            <span>小红书号 <span style="color: #f00">*</span></span
            ><a-input
              v-model.trim="addMediaParams.account"
              placeholder="输入小红书号"
              style="width: 300px; margin-left: 10px"
            ></a-input>
          </div>
          <div>
            <a-popover title="">
              <template slot="content">
                <img style="width: 300px" src="@/assets/images/xhs-guide.png" alt="引导图" />
                <div style="font-weight: bold">打开小红书账号主页，点击“小红书号“</div>
              </template>
              <a-button icon="question-circle" type="link">如何获取小红书号</a-button>
            </a-popover>
          </div>
        </a-tab-pane>
      </a-tabs>
      <template slot="footer">
        <a-button key="back" @click="handleMediaClose"> 取消 </a-button>
        <a-button key="submit" type="primary" :loading="addLoading" @click="handleAdd(addMediaParams.type)">
          {{ addMediaParams.type === 'batch' ? '确定' : '下一步' }}
        </a-button>
      </template>
    </a-modal>

    <a-modal
      width="500px"
      :zIndex="1001"
      title="批量添加结果"
      :maskClosable="false"
      :visible="showFailModal"
      @close="showFailModal = false"
      @cancel="showFailModal = false"
    >
      <div style="color: #f00" v-if="failData.failList && failData.failList.length">
        <a-icon type="close-circle" style="margin-right: 6px; color: #f00" />导入失败 {{ failData.failList.length }} 个
        矩阵里没找到：未找到小红书号，请检查拼写，或还没通过风火轮添加
        <div>
          <span v-for="(v, i) in failData.failList">{{ v }}{{ i === failData.failList.length - 1 ? '' : '，' }}</span>
        </div>
      </div>
      <div style="color: #333333" v-if="failData.existList && failData.existList.length">
        <a-icon type="info-circle" theme="filled" style="margin-right: 6px; color: #f59a23" />已在运营
        {{ failData.existList.length }} 个，自动忽略
        <span v-for="(v, i) in failData.existList">{{ v }}{{ i === failData.existList.length - 1 ? '' : '，' }}</span>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" @click="showFailModal = false">确认</a-button>
      </template>
    </a-modal>

    <a-modal
      width="500px"
      :maskClosable="false"
      :zIndex="1001"
      :title="`已选1个账号，设置`"
      :visible="showSetManageModal"
      @close="handleShowSetClose"
      @cancel="handleShowSetClose"
    >
      <a-form-model
        class="form"
        ref="characterForm"
        :model="characterForm"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <a-form-model-item label="性别" prop="sexual">
          <a-radio-group v-model="characterForm.sexual">
            <a-radio value="MALE">男</a-radio>
            <a-radio value="FEMALE"> 女 </a-radio>
            <a-radio value="NONE"> 无性别 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="人设" prop="personalityType">
          <a-select v-model="characterForm.personalityType">
            <a-select-option v-for="(item, index) in personalityTypeList" :key="index" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="dressName">
          <template slot="label">
            <a-popover>
              <template slot="content">
                <p>发布笔记时自动填入地点信息</p>
              </template>
              门店地点<a-icon style="margin-left: 5px" type="exclamation-circle" />
            </a-popover>
          </template>
          <a-select
            v-model="characterForm.dressName"
            placeholder="支持搜索相关地点信息"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            show-search
            allowClear
            @search="handlePositionSearch"
            @change="handleGetPosition"
            :loading="positionLoading"
            option-label-prop="label"
          >
            <a-select-option
              v-for="(value, index) in positionList"
              :key="index"
              :label="value.name"
              :value="value.poiId"
            >
              <div>{{ value.name }}</div>
              <div style="font-size: 12px; color: #cccccc; overflow: hidden; text-overflow: ellipsis">
                {{ value.subname }}
              </div>
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <div style="color: #878787; text-align: center">*运营负责人根据账号所属成员的负责人自动设置</div>
      </a-form-model>
      <template slot="footer">
        <a-button @click="handleShowSetClose">取消</a-button>
        <a-button key="submit" type="primary" :loading="addLoading" @click="handleSetConfirm">确定</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { trackRequest } from '@/utils/track';
import { personalityTypeList } from '../data';
import api from '@/api/xhsAgencyApi';

export default {
  name: 'AddAccountModalComp',
  props: {},
  components: {},
  data() {
    return {
      personalityTypeList,
      // 添加小红书号
      showAddMedia: false,
      addMediaParams: {
        type: 'batch',
        account: undefined,
      },
      errorData: {
        failNum: 0,
        repeatNum: 0,
      },
      showError: false,
      addLoading: false,
      showFailModal: false,
      failData: {
        existList: [],
        failList: [],
      },
      characterForm: {
        id: undefined,
        sexual: 'NONE',
        personalityType: 'SALE',
        dressName: undefined,
      },
      positionList: [],
      currentPosition: {},
      positionLoading: false,
      showSetManageModal: false,
    };
  },
  computed: {
    addCount() {
      return this.addMediaParams.account ? this.addMediaParams.account.split('\n').filter((v) => v).length : 0;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    openUpload() {
      this.showAddMedia = true;
    },
    handleMediaClose() {
      this.addMediaParams = this.$options.data().addMediaParams;
      this.showAddMedia = false;
    },
    async handleAdd(type) {
      const params = {
        authorIds: this.addMediaParams.account?.split('\n')?.filter((v) => v) || [],
      };
      if (params.authorIds.length === 0) return this.$message.error('请输入账号');
      if (params.authorIds.length > 100) return this.$message.error('一次最多添加100个账号');
      this.addLoading = true;
      const { data, code, message } = await api.handleAddMedia(params).finally(() => (this.addLoading = false));
      if (code === 200) {
        this.characterForm.id = data.successList?.length ? data.successList[0] : undefined;
        if (this.characterForm.id) {
          this.$emit('refresh');
          this.handleTrack('add_media_success');
        }
        if (type === 'batch') {
          this.handleMediaClose();
          if (data.existList?.length || data.failList?.length) {
            this.failData = data;
            this.showFailModal = true;
            return;
          }
          this.$message.success('添加成功');
        }
        if (type === 'single') {
          if (data.existList?.length || data.failList?.length) {
            this.failData = data;
            this.showFailModal = true;
            return this.$message.error('添加失败，未找到媒体号');
          }
          this.handleMediaClose();
          this.showSetManageModal = true;
        }
      } else {
        this.$message.error(message);
      }
    },
    handleTabChange(type) {
      this.addMediaParams = this.$options.data().addMediaParams;
      this.addMediaParams.type = type;
      if (type === 'batch') {
        this.handleTrack('add_media_batch');
      }
      if (type === 'batch') {
        this.handleTrack('edit_media');
      }
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
    handleSetManager(data) {
      if (data?.id) {
        const { sexual, postLoc, personalityType } = data;
        this.currentPosition = postLoc || {};
        this.characterForm = {
          id: data.id,
          dressName: postLoc?.name || undefined,
          sexual: sexual ? sexual : 'NONE',
          personalityType,
        };
      }
      if (this.characterForm.id) {
        this.showSetManageModal = true;
      }
    },
    handlePositionSearch(value) {
      if (value) {
        this.positionLoading = true;
        this.handleRequest(
          'getPublishLocation',
          (data) => {
            this.positionList = data;
          },
          {
            keyword: value,
          }
        ).finally(() => (this.positionLoading = false));
      }
    },
    handleGetPosition(value) {
      if (value) {
        this.currentPosition = this.positionList.find((val) => val.poiId === value);
      } else {
        this.currentPosition = {};
      }
    },
    async handleSetConfirm() {
      const postLoc = {
        name: this.currentPosition.name,
        poiId: this.currentPosition.poiId,
        poiType: this.currentPosition.poiType,
        subname: this.currentPosition.subname,
      };
      const params = {
        id: this.characterForm.id,
        postLoc,
        sexual: this.characterForm.sexual,
        personalityType: this.characterForm.personalityType,
      };

      this.addLoading = true;
      const { code, message } = await api.editPersonality(params).finally(() => (this.addLoading = false));
      if (code === 200) {
        this.$message.success('设置成功');
        this.handleShowSetClose();
        this.$emit('refresh');
      } else {
        this.$message.error(message);
      }
    },
    handleShowSetClose() {
      this.showSetManageModal = false;
      this.characterForm = this.$options.data().characterForm;
      this.currentPosition = {};
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
